<template>
  <div class="v-mobile-navigation">
    <div class="v-container">
      <div class="v-row">
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link"
            :class="{ 'v-active': !Guid.IsNullOrEmpty(menuStore.CurrentGroupId) }"
            :href="appConfig.VueSettingsPreRun.Links.DefaultMenuGroup"
            :label="translate('footerPage.mainMenu')"
          >
            <div class="v-bar--icon">
              <icon-old-menu-plate />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.mainMenu')" />
            </div>
          </arora-nuxt-link>
        </div>
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link"
            :href="appConfig.VueSettingsPreRun.Links.ContactsLink"
            :label="translate('footerPage.contacts')"
            active-class="v-active"
          >
            <div class="v-bar--icon">
              <icon-old-address-mark-light />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.contacts')" />
            </div>
          </arora-nuxt-link>
        </div>
        <div class="v-col-3">
          <arora-nuxt-link
            class-name="v-main-menu-link"
            :href="appConfig.VueSettingsPreRun.Links.PersonalCabinetLink"
            :label="translate('footerPage.profile')"
            active-class="v-active"
          >
            <div class="v-bar--icon">
              <icon-old-account-user />
            </div>
            <div class="v-bar--text">
              <span v-html="translate('footerPage.profile')" />
            </div>
          </arora-nuxt-link>
        </div>
        <div class="v-col-3">
          <lazy-cart-small-basket />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Guid } from '~api/consts'

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const menuStore = useMenuStore()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-mobile-navigation {
  position: fixed;
  z-index: 1002;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(77px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: center;
  background: variables.$FooterBackgroundColor;
  box-shadow: variables.$FloatShadow;
  &:after {
    background: variables.$FooterColor;
    opacity: 0.1;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 1px;
    position: absolute;
  }
  .v-main-menu-link {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    .v-bar {
      &--icon {
        max-width: 28px;
        max-height: 28px;
        margin-bottom: 8px;
        position: relative;

        svg {
          width: auto;
          height: 28px;
        }

        .v-lhun {
          opacity: 0.3;
          * {
            fill: variables.$FooterColor;
          }
          .v-fill-none {
            fill: none;
            stroke: variables.$FooterColor;
          }
        }
        .v-poros {
          * {
            stroke: variables.$FooterColor;
            opacity: 0.3;
          }
        }
        .v-badge-root {
          position: absolute;
          top: 0;
          right: -5px;
        }
      }

      &--text {
        color: variables.$FooterColor;
        opacity: 0.3;
      }
    }
    &.v-active {
      .v-bar {
        &--icon {
          * {
            stroke: variables.$PrimaryBackgroundColor;
            opacity: 1;
          }
          .v-lhun {
            * {
              fill: variables.$PrimaryBackgroundColor;
            }

            .v-fill-none {
              fill: none;
              stroke: variables.$PrimaryBackgroundColor;
            }
          }
        }
        &--text {
          color: variables.$PrimaryBackgroundColor;
          opacity: 1;
        }
      }
    }
  }
}
</style>
